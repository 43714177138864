// Site.js
import React, { useState } from 'react';
import Header from './components/Header';
import MapPopup from './components/MapPopup'; 
import MobileMenu from './components/MobileMenu';

import VideoPlayer from './components/VideoPlayer';
import OfferArea from './components/OfferArea';
import MarqueArea from './components/MarqueArea';
import ClientSayArea from './components/ClientSayArea';
import GalleryButton from './components/GalleryButton';
import TrailersWithOptions from './components/TrailersWithOptions';
import TrailerDescription from './components/TrailerDescription';
import TrailerDescription2 from './components/TrailerDescription2';
import TrailerDescription3 from './components/TrailerDescription3';
import TrailerDescription4 from './components/TrailerDescription4';
import CatalogProduct from './components/CatalogProduct';
import Footer from './components/Footer';
import CenterText from './components/CenterText';

function Site() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Состояние меню

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const openMenu = () => { // Открытие меню
    setIsMenuOpen(true);
  };

  const closeMenu = () => { // Закрытие меню
    setIsMenuOpen(false);
  };

  return (
    <div>
      {/* Передаем функции открытия и закрытия попапа и открытия меню в Header */}
      <Header 
        onOpenPopup={handleOpenPopup} 
        onOpenMenu={openMenu} 
        onClosePopup={handleClosePopup} // Добавлено
      />
      
      {/* Условно рендерим MobileMenu только если isMenuOpen == true */}
      {isMenuOpen && (
        <MobileMenu 
          onOpenPopup={handleOpenPopup} 
          onCloseMenu={closeMenu} 
          onClosePopup={handleClosePopup} // Добавлено, если нужно
        />
      )}

      
      <VideoPlayer />
      <CatalogProduct />
      <GalleryButton />
      <TrailersWithOptions />
      <MarqueArea />
      <OfferArea />

      <TrailerDescription />
      <TrailerDescription2 />
      <TrailerDescription3 />
      <TrailerDescription4 />
      <ClientSayArea />

      <Footer onOpenPopup={handleOpenPopup} />
      <CenterText />
      
      {/* Рендерим попап, если isPopupOpen == true */}
      {isPopupOpen && <MapPopup onClose={handleClosePopup} />}
    </div>
  );
}

export default Site;
