// ClientSayArea.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ClientSayArea.css'; // Убедитесь, что путь корректный

const ClientSayArea = () => {
  const testimonials = [
    {
      text: `Все отлично. Созвонились, выбрал комплектацию.
      Приехал, забрал. На вопросы отвечают быстро. После покупки помогают если что то нужно.
      Своих денег стоит однозначно.`,
      author: 'Денис Юшков',
    },
    {
      text: `Если сказать что прицеп отличный ,это ничего не сказать. 
      Все продумано до мелочей. Внешний вид просто бомба,
       функционал огнище все что можно придумать в прицепе, все есть.
       На улице люди подходят спрашивают где такой купил.
       Парням огромнейшее спасибо и суперпродаж им. Так держать!!!`,
      author: 'mkt',
    },
    {
      text: `Отличный прицеп просто слов нет не могу нарадоваться покупке очень многофункционален
      и смотрится шикарно делали как будто себе,
      а не на продажу всё очень аккуратно и продуманно данную компанию советую
      и ребята очень вежливые и приятные на общение буду обращаться еще.`,
      author: 'Лев',
    },
    {
      text: `Нормальные контактные Ребята, все объяснили, 
      забронировали прицеп и привезли как договаривались. 
      Товар полностью соответствует фото!
      Качество достаточно неплохое + нормальное функциональное наполнение! 
      Рекомендую к покупке.`,
      author: 'Дмитрий',
    },
    {
      text: `Купил прицеп, ни разу не пожалел. Отличный прицеп.
      Все продумано. Много функций которые удобно использовать. 
      А еще он красивый. ))) Сотрудники очень внимательные, дают обратную связь,
      внимательно прислушиваются к отзывам владельцев.
      Некоторые вещи реализуют в конструкции согласно пожеланий и отзывов. 
      В общем если нужен красивый и качественный прицеп, вам сюда.`,
      author: 'Станислав Логинов',
    },
    {
      text: `Прицеп огонь! Качество исполнения, материалы и дизайн!
       Всем кто хочет себе качественный и оригинальный прицеп, однозначно советую!
       Приятные ощущения от пользования каждый раз!
       Ребята молодцы, готовы к индивидуальному подходу и эксклюзивным доработкам!`,
      author: 'Михаил П.',
    },
    // Добавьте дополнительные отзывы по необходимости
  ];

  const settings = {
    dots: true, // Показывать точки навигации
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Интервал автопрокрутки
    arrows: true, // Показывать стрелки навигации
    adaptiveHeight: true,
  };

  return (
    <section id="say" className="client-say-area-home-3 client-say-area-home-1">
      <div className="container">
        <div className="client-say-title">
          <h3 className="font-size-1-24">Отзывы наших клиентов</h3>
        </div>
        <div className="client-say-wrapper">
          <Slider {...settings} className="client-say-home-3">
            {testimonials.map((testimonial, idx) => (
              <div key={idx}>
                <div className="client-say-inner">
                  <p className="font-size-1-24">
                    {testimonial.text.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                  <span className="font-size-1-14 client-work-name">
                    {testimonial.author}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ClientSayArea;
