// MobileMenu.js
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './MobileMenu.css';
const MobileMenu = ({ onOpenPopup, onCloseMenu }) => {
  // Состояние для управления открытием подменю
  const [openSubmenus, setOpenSubmenus] = useState({});
  const menuRef = useRef(null); // Для обработки кликов вне меню

  // Функция для переключения подменю
  const toggleSubmenu = (menuId) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

  // Функция для скролла к секции и закрытия меню
  const scrollToSection = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    onCloseMenu();
  };

  // Функция для открытия попапа и закрытия меню
  const handleContactsClick = (e) => {
    e.preventDefault();
    onOpenPopup();
    onCloseMenu();
  };

  // Обработчик клика вне меню для его закрытия
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onCloseMenu();
    }
  };

  // Добавляем обработчик клика вне меню при монтировании
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="nft-mobile-menu mobile-menu-active" ref={menuRef}>
      <button className="close-menu" onClick={onCloseMenu} aria-label="Закрыть меню">
        <i className="fa-solid fa-xmark"></i>
      </button>
      <ul>
        <li>
          <a href="#background-video" onClick={(e) => scrollToSection(e, 'background-video')}>
            О прицепах Voyage
          </a>
        </li>
        <li>
          <a href="#product" onClick={(e) => scrollToSection(e, 'product')}>
            Модельный ряд
          </a>
        </li>
        <li>
          <a href="#calculator" onClick={(e) => scrollToSection(e, 'calculator')}>
            Конфигуратор
          </a>
        </li>
        <li>
          <a href="#about" onClick={(e) => scrollToSection(e, 'about')}>
            Преимущества
          </a>
        </li>
        <li>
          <a href="#say" onClick={(e) => scrollToSection(e, 'say')}>
            Отзывы
          </a>
        </li>
        <li>
          <a href="#contact" onClick={(e) => scrollToSection(e, 'contact')}>
            Где купить?
          </a>
        </li>
        <li>
          {/* Пункт меню "Контакты" для открытия попапа */}
          <a href="#" onClick={handleContactsClick}>
            Контакты
          </a>
        </li>
        
      </ul>
    </div>
  );
};

// Добавляем PropTypes для проверки типов пропсов
MobileMenu.propTypes = {
  onOpenPopup: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
};

export default MobileMenu;
