// Header.js
import React, { useEffect, useState, useRef } from 'react';
import './Header.css';
import PropTypes from 'prop-types'; // Импорт PropTypes

const Header = ({ onOpenPopup, onOpenMenu, onClosePopup }) => { // Добавлено onClosePopup
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);

  // Функция плавного скролла к указанной секции и закрытия попапа
  const scrollToSection = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    onClosePopup(); // Закрываем попап при клике на любой пункт меню
  };

  // Используем IntersectionObserver для липкого заголовка
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      { threshold: 1, rootMargin: "-100px 0px 0px 0px" }
    );

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observer.unobserve(headerRef.current);
      }
    };
  }, []);

  return (
    <header ref={headerRef} className={`header-area-1 header-sticky ${isSticky ? 'sticky-on' : ''}`}>
      <div className="header-wrapper">
        <div className="menu-bar">
        
          <button 
            className="menu-bar-btn" 
            onClick={onOpenMenu} 
            aria-label="Открыть меню"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="15" viewBox="0 0 49 15" fill="#CCDEFF">
              <line x1="0.249" y1="1.416" x2="48.622" y2="1.416" stroke="#CCDEFF" strokeWidth="2" />
              <line x1="0.249" y1="13.922" x2="48.622" y2="13.922" stroke="#CCDEFF" strokeWidth="2" />
            </svg>
          </button>
        </div>
         <img src="/uploads/tree.gif" className="tree" />
        <div className="logo">
          <a href="/">

            <img src="assets/images/logo.png" className="logotip" alt="VRE" />

          </a>
        </div>
        <div className="main-menu main-menu-1">
          <ul>
            <li>
              <a href="#about" className="font-size-1-14 transition-1" onClick={(e) => scrollToSection(e, 'about')}>
                О прицепах Voyage
              </a>
            </li>
            <li>
              <a href="#product" className="font-size-1-14 transition-1" onClick={(e) => scrollToSection(e, 'product')}>
                Модельный ряд
              </a>
            </li>
             <li>
              {/* Пункт меню "Контакты" для открытия попапа */}
              <a href="/gallery?type=all" className="font-size-1-14 transition-1" onClick={(e) => { 
               
              }}>
                Галерея
              </a>
            </li>
            <li>
              <a href="#calculator" className="font-size-1-14 transition-1" onClick={(e) => scrollToSection(e, 'calculator')}>
                Конфигуратор
              </a>
            </li>
            <li>
              <a href="#about" className="font-size-1-14 transition-1" onClick={(e) => scrollToSection(e, 'about')}>
                Преимущества
              </a>
            </li>
            <li>
              <a href="#say" className="font-size-1-14 transition-1" onClick={(e) => scrollToSection(e, 'say')}>
                Отзывы
              </a>
            </li>
            <li>
              <a href="#contact" className="font-size-1-14 transition-1" onClick={(e) => scrollToSection(e, 'contact')}>
                Где купить?
              </a>
            </li>
            
          </ul>
        </div>
        <div className="get-in-touch">
          <a href="https://t.me/voyageclassic" target="_blank" rel="nofollow noopener" className="icon-soc">
            <img width="30px" src="/uploads/soc/telegram.svg" alt="Telegram" />
          </a>

          <a href="https://www.youtube.com/@voyage77" target="_blank" rel="nofollow noopener" className="icon-soc">
            <img width="30px" src="/uploads/soc/youtube.svg" alt="YouTube" />
          </a>
          <a href="https://ok.ru/group/70000008413227" target="_blank" rel="nofollow noopener" className="icon-soc">
           <img width="30px" src="/uploads/soc/ok.svg" alt="YouTube" />
          </a>
          <a href="https://www.instagram.com/pricepyvoyage/profilecard/?igsh=d3NkdmNxZmZ6bWFr" target="_blank" rel="nofollow noopener" className="icon-soc">
            <img width="30px" src="/uploads/soc/inst.svg" alt="YouTube" />
          </a>
          <a href="https://vk.com/voyagetrailer" target="_blank" rel="nofollow noopener" className="icon-soc">
            <img width="30px" src="/uploads/soc/vk.svg" alt="VK" />
          </a>
           <a href="https://rutube.ru/channel/49653067/" target="_blank" rel="nofollow noopener" className="icon-soc">
            <img width="30px" src="/uploads/soc/rutube.svg" alt="VK" />
          </a>
        </div>
      </div>
    </header>
  );
};

// Добавляем PropTypes для проверки типов пропсов
Header.propTypes = {
  onOpenPopup: PropTypes.func.isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

export default Header;
