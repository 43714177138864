import React, { useState, useEffect } from 'react';
import './TrailerDescription.css';

const TrailerDescription = () => {
  const images = [
    '/uploads/advantages/bl1/Adv_B1 (1).jpg',
    '/uploads/advantages/bl1/Adv_B1 (2).jpg',
    '/uploads/advantages/bl1/Adv_B1 (3).jpg',
    '/uploads/advantages/bl1/Adv_B1 (4).jpg',
    '/uploads/advantages/bl1/Adv_B1 (5).jpg',
    // Добавьте остальные изображения по необходимости
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [fadeIn, setFadeIn] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return; // Не запускаем слайдер на мобильных устройствах

    const interval = setInterval(() => {
      setFadeIn(false); // Начинаем затухание текущего изображения

      setTimeout(() => {
        setCurrentImageIndex(nextImageIndex);
        setNextImageIndex((nextImageIndex + 1) % images.length);
        setFadeIn(true); // Показываем следующее изображение
      }, 1000); // Должно совпадать с длительностью перехода в CSS
    }, 5000); // Интервал между сменой изображений

    return () => clearInterval(interval);
  }, [nextImageIndex, images.length, isMobile]);

  const content = (
    <div className="trailer-text">
      <h1>ПРИЦЕПЫ VOYAGE</h1>
      <p>ДЛЯ ЛЮБЫХ ЗАДАЧ</p>
      <ul className="features-list">
        <li className="feature-item">
          <div className="icon-wrapper">
            <div className="img-wrapper">
              <img
                className="feature-img"
                src="assets/images/trailers/bl1svg/1.svg"
                alt="Оригинальный современный дизайн"
              />
            </div>
          </div>
          <div className="text-wrapper">
            <h3 className="feature-title">Оригинальный современный дизайн</h3>
            <div className="feature-description">Широкая цветовая гамма борта</div>
          </div>
        </li>
        <li className="feature-item">
          <div className="icon-wrapper">
            <div className="img-wrapper">
              <img
                className="feature-img"
                src="assets/images/trailers/bl1svg/2.svg"
                alt="Оцинкованный стальной каркас прицепа"
              />
            </div>
          </div>
          <div className="text-wrapper">
            <h3 className="feature-title">Оцинкованный стальной каркас прицепа</h3>
            <div className="feature-description">Защита методом горячего цинкования</div>
          </div>
        </li>
        <li className="feature-item">
          <div className="icon-wrapper">
            <div className="img-wrapper">
              <img
                className="feature-img"
                src="assets/images/trailers/bl1svg/3.svg"
                alt="Комбинированная светодиодная оптика"
              />
            </div>
          </div>
          <div className="text-wrapper">
            <h3 className="feature-title">Комбинированная светодиодная оптика</h3>
            <div className="feature-description">Современная оптика от ведущих производителей</div>
          </div>
        </li>
        <li className="feature-item">
          <div className="icon-wrapper">
            <div className="img-wrapper">
              <img
                className="feature-img"
                src="assets/images/trailers/bl1svg/4.svg"
                alt="Подъемный механизм каркаса тента"
              />
            </div>
          </div>
          <div className="text-wrapper">
            <h3 className="feature-title">Подъемный механизм каркаса тента</h3>
            <div className="feature-description">Каркас-трансформер на пневмоупорах</div>
          </div>
        </li>
      </ul>
    </div>
  );

  if (isMobile) {
    return (
      <div
        className="trailer-description mobile"
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
        }}
      >
        {content}
      </div>
    );
  }

  return (
    <div className="trailer-description">
      {/* Текущее изображение */}
      <img
        src={images[currentImageIndex]}
        alt="Фоновое изображение прицепа"
        className={`trailerdesck-image ${fadeIn ? 'fade-in' : 'fade-out'}`}
      />
      {/* Следующее изображение */}
      <img
        src={images[nextImageIndex]}
        alt="Фоновое изображение прицепа"
        className={`trailerdesck-image next-image ${fadeIn ? 'fade-out' : 'fade-in'}`}
      />
      {content}
    </div>
  );
};

export default TrailerDescription;
