// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
  text-align: center;
  
}

.center-text {
  font-size: 14px; /* Размер шрифта */
  font-family: Arial, sans-serif; /* Шрифт */
  color: #fff; /* Цвет текста */
}
`, "",{"version":3,"sources":["webpack://./src/components/CenterText.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,kBAAkB;;AAEpB;;AAEA;EACE,eAAe,EAAE,kBAAkB;EACnC,8BAA8B,EAAE,UAAU;EAC1C,WAAW,EAAE,gBAAgB;AAC/B","sourcesContent":[".center-text-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n \n  text-align: center;\n  \n}\n\n.center-text {\n  font-size: 14px; /* Размер шрифта */\n  font-family: Arial, sans-serif; /* Шрифт */\n  color: #fff; /* Цвет текста */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
