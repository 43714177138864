// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* C25Modal.css */

.popup-image-slider {
  margin-bottom: 20px;
}

.popup-image {
  width: 100%;
  height: auto;
}

.icon-row-popup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0;
}

.icon-image-popup {
  width: 30px;
  height: 30px;
}

.popup-description {
  margin-bottom: 10px;
}
/* IndividualModal.css */

/* Класс для зелёной кнопки */
.green-button {
  background-color: #52c41a !important; /* Зелёный цвет Ant Design */
  border-color: #52c41a !important;
  color: #fff !important; /* Белый текст */
}

.green-button:hover,
.green-button:focus {
  background-color: #73d13d !important; /* Более светлый зелёный при наведении */
  border-color: #73d13d !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/IndividualModal.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;AACA,wBAAwB;;AAExB,6BAA6B;AAC7B;EACE,oCAAoC,EAAE,4BAA4B;EAClE,gCAAgC;EAChC,sBAAsB,EAAE,gBAAgB;AAC1C;;AAEA;;EAEE,oCAAoC,EAAE,wCAAwC;EAC9E,gCAAgC;AAClC","sourcesContent":["/* C25Modal.css */\n\n.popup-image-slider {\n  margin-bottom: 20px;\n}\n\n.popup-image {\n  width: 100%;\n  height: auto;\n}\n\n.icon-row-popup {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  margin: 15px 0;\n}\n\n.icon-image-popup {\n  width: 30px;\n  height: 30px;\n}\n\n.popup-description {\n  margin-bottom: 10px;\n}\n/* IndividualModal.css */\n\n/* Класс для зелёной кнопки */\n.green-button {\n  background-color: #52c41a !important; /* Зелёный цвет Ant Design */\n  border-color: #52c41a !important;\n  color: #fff !important; /* Белый текст */\n}\n\n.green-button:hover,\n.green-button:focus {\n  background-color: #73d13d !important; /* Более светлый зелёный при наведении */\n  border-color: #73d13d !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
