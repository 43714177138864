// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {

  margin: 0 auto;
   color: #d1d1d1;
    font-size: 26px;
}

.about-p {
     color: #d1d1d1;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  text-align: justify;
}

/* Мобильные устройства (до 767px) */
@media (max-width: 767px) {
  .about-p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
}

/* Планшеты (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .about-p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}

/* Десктопы (1200px и выше) */
@media (min-width: 1200px) {
  .about-p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/OfferArea.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;GACb,cAAc;IACb,eAAe;AACnB;;AAEA;KACK,cAAc;EACjB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA,oCAAoC;AACpC;EACE;IACE,iBAAiB;IACjB,mBAAmB;EACrB;AACF;;AAEA,8BAA8B;AAC9B;EACE;IACE,eAAe;IACf,qBAAqB;EACvB;AACF;;AAEA,6BAA6B;AAC7B;EACE;IACE,iBAAiB;IACjB,mBAAmB;EACrB;AACF","sourcesContent":[".about {\n\n  margin: 0 auto;\n   color: #d1d1d1;\n    font-size: 26px;\n}\n\n.about-p {\n     color: #d1d1d1;\n  font-size: 1rem;\n  line-height: 1.6;\n  margin-bottom: 1.5rem;\n  text-align: justify;\n}\n\n/* Мобильные устройства (до 767px) */\n@media (max-width: 767px) {\n  .about-p {\n    font-size: 0.9rem;\n    margin-bottom: 1rem;\n  }\n}\n\n/* Планшеты (768px - 1199px) */\n@media (min-width: 768px) and (max-width: 1199px) {\n  .about-p {\n    font-size: 1rem;\n    margin-bottom: 1.5rem;\n  }\n}\n\n/* Десктопы (1200px и выше) */\n@media (min-width: 1200px) {\n  .about-p {\n    font-size: 1.2rem;\n    margin-bottom: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
