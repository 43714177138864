// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-sticky {
  position: relative;
  top: 0;
  width: 100%;
  transition: all 0.3s ease;
}
.menu-bar-btn{
  background: none;
}
.tree{
  width: 60px;
  margin-left: 20px;
}
.header-sticky.sticky-on {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
}
  @media (max-width: 1200px) {
.phone-numbers{
      display: none;
    }
  .tree{
  width: 50px;
  margin-left: 5px;
}
  }
  @media (max-width: 789px) {
    .tree{
  width: 50px;
  margin: 0px;
}
  }`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,aAAa;EACb,wCAAwC;;AAE1C;EACE;AACF;MACM,aAAa;IACf;EACF;EACA,WAAW;EACX,gBAAgB;AAClB;EACE;EACA;IACE;EACF,WAAW;EACX,WAAW;AACb;EACE","sourcesContent":[".header-sticky {\n  position: relative;\n  top: 0;\n  width: 100%;\n  transition: all 0.3s ease;\n}\n.menu-bar-btn{\n  background: none;\n}\n.tree{\n  width: 60px;\n  margin-left: 20px;\n}\n.header-sticky.sticky-on {\n  position: fixed;\n  z-index: 1000;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  \n}\n  @media (max-width: 1200px) {\n.phone-numbers{\n      display: none;\n    }\n  .tree{\n  width: 50px;\n  margin-left: 5px;\n}\n  }\n  @media (max-width: 789px) {\n    .tree{\n  width: 50px;\n  margin: 0px;\n}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
